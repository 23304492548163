import React from 'react'
import logo from '../images/header_logo.png'
import form_icn from '../images/form_icn.png'

const Header = () => {
  return (
    <>
    <section className='header_wraper mb-7 pt-5 section_container'>
        <div className='container flex align-middle justify-between'>
          <div className='header_logo'>
            <img src={logo} alt='logo' className='img-fluid'/>
          </div>
          <div className='header_btn'>
          <button type="button" className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Search</button>
          <button type="button" className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Quick Links</button>
          </div>
        </div>
    </section>

    <section className='bottom_menu_wrapper'>
        <div className='menu__wrapper flex align-middle justify-around'>
           <div className='form_icon'>
            <img src={form_icn} alt='imag' className='header_icon'/>
             <p>Forms</p>
           </div>

           <div className='form_icon'>
            <img src={form_icn} alt='imag' className='header_icon'/>
             <p>Policies</p>
           </div>

           <div className='form_icon'>
            <img src={form_icn} alt='imag' className='header_icon'/>
             <p>Knowledge Center</p>
           </div>

           <div className='form_icon'>
            <img src={form_icn} alt='imag' className='header_icon'/>
             <p>Trainings</p>
           </div>

           <div className='form_icon'>
            <img src={form_icn} alt='imag' className='header_icon'/>
             <p>Contact</p>
           </div>
        </div>
    </section>

    </>
  )
}

export default Header