import './App.css';
import Header from './Components/Header';
import Home from './Components/Home';

function App() {
  return (
   <>
    <Header />
    <Home />
   </>
   

  );
}

export default App;
