import React from 'react'
import bnr from '../images/bnr.webp'
import Forms from './Forms'
import Sidebar from './Sidebar'

const Home = () => {
  return (
    <>
     <section className="bg-gray-200">
    <div className="container mx-auto  flex items-center justify-center">
      <div className="max-lg mx-auto p-6 rounded-lg">
        <img src={bnr} alt="" class="w-full h-auto mb-4 rounded-lg" />
       
      </div>
    </div>
  </section> 


<section className='form_wrapper section_container'>
    <div className='container'>
        <div className='grid grid-cols-2 gap-4'>
            <div>
            <Forms />
            </div>

            <div>
            <Sidebar />
            </div>
        </div>
   
   
    </div>
</section>


  
      </>
  )
}

export default Home